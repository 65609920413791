import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function LoadingWrapper({ isLoading, children, className }) {
  if (isLoading) {
    return (
      <div
        className={classnames(className, 'w-100 loading-spinner')}
        data-test-id="loading-wrapper"
      />
    );
  }

  if (typeof children === 'function') {
    return children();
  }

  return children;
}

LoadingWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string
};

LoadingWrapper.defaultProps = {
  isLoading: false,
  className: null
};
